var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('search-item',{attrs:{"searchString":_vm.searchString,"no-results":true},on:{"setSearchString":_vm.setSearchString,"get-results":_vm.getItemResults,"clear-input":_vm.clearItem}})],1),_c('v-col',{staticClass:"d-flex align-self-center justify-end",attrs:{"cols":"9"}},[_c('v-btn-toggle',{attrs:{"color":"primary"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openItemModal(_vm.defaultItem)}}},[_vm._v("Nieuw Inkoop artikel")])],1)],1),_c('v-col',[_c('v-btn-toggle',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectCategory(false)}}},[_vm._v(" Alles ")]),_vm._l((_vm.$store.state.settings.categories),function(category){return _c('v-btn',{key:category,attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectCategory(category)}}},[_vm._v(" "+_vm._s(category)+" ")])}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.selectArchived}},[_vm._v("Archief")])],2)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"options":_vm.options,"server-items-length":_vm.itemsLength,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 25, 50, -1]
    },"item-key":"_id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.sort",fn:function(ref){
    var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.sort),expression:"item.sort"}],attrs:{"size":"3"},domProps:{"value":(item.sort)},on:{"change":function($event){return _vm.updateItem(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "sort", $event.target.value)}}})]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openItemModal(item)}}},[_c('b',[_vm._v(_vm._s(item.name))])])]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm.toCurrency(item.price)))]}},{key:"item.VAT",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.VAT)+"%")]}},{key:"item.unitAmount",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.unitAmount)+"kg")]}},{key:"item.stock",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.stock)+"kg")]}},{key:"item.updated",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm.toDateString(item.updated)))]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"large":""},on:{"click":function($event){return _vm.openPrintLabelModal(item)}}},[_vm._v("mdi-label")])]}}],null,true)}),(_vm.purchaseItemModal)?_c('purchase-item-modal',{attrs:{"purchaseItemModal":_vm.purchaseItemModal,"item":_vm.item},on:{"insert-item":_vm.insertItem,"update-item":_vm.updateItem,"delete-item":_vm.deleteItem,"close-item-modal":_vm.closeItemModal}}):_vm._e(),(_vm.printLabelModal)?_c('print-label-modal',{attrs:{"printLabelModal":_vm.printLabelModal,"item":_vm.item},on:{"update-item-supplier-and-lot-number":_vm.updateItemSupplierAndLotNumber,"close-print-label-modal":_vm.closePrintLabelModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }